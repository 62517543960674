<template>
  <div>
    <el-table
      class="table-responsive align-items-center table-flush"
      header-row-class-name="thead-light"
      :data="users"
    >
      <el-table-column label="" width="150px">
        <template v-slot="{ row }">
          <img
            v-if="row.profile_image"
            :src="row.profile_image"
            class="avatar rounded-circle mr-3"
          /><br />
          <span>
            <icon-check :checked="!!row.active" />
            {{ $t("USERS.ACTIVE") }} </span
          ><br />
          <span>
            <icon-check :checked="!!row.is_staff" />
            {{ $t("USERS.IS_STAFF") }}
          </span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('COMMON.NAME')" prop="firstname">
        <template v-slot="{ row }">
          <span>{{ `${row.firstname} ${row.lastname}` }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('COMMON.EMAIL')" prop="email" />
      <el-table-column :label="$t('COMMON.ROLE')" prop="roles.name">
        <template v-slot="{ row }">
          <span v-if="row.roles[0]">
            <router-link
              :to="{ name: 'View Role', params: { id: row.roles[0].id } }"
            >
              {{ row.roles[0].name }}
            </router-link>
          </span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('COMMON.CREATED_AT')">
        <template v-slot="{ row }">
          {{$timeZoneDateFormat(row.created_at)}}
        </template>
      </el-table-column>
      <el-table-column min-width="50px" align="center">
        <div slot-scope="{ row }" class="table-actions">
          <el-tooltip
            :content="$t('COMMON.VIEW')"
            placement="top"
            v-if="$currentUserCan($permissions.PERM_VIEW_USERS)"
          >
            <a
              type="text"
              @click="viewUser(row)"
              class="table-action"
              data-toggle="tooltip"
              style="cursor: pointer"
            >
              <i class="fas fa-eye"></i>
            </a>
          </el-tooltip>

          <el-tooltip :content="$t('COMMON.DELETE')" placement="top">
            <a
              type="text"
              @click="deleteUser(row)"
              class="table-action"
              data-toggle="tooltip"
              style="cursor: pointer"
            >
              <i class="fas fa-trash"></i>
            </a>
          </el-tooltip>
        </div>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import IconCheck from "@/components/IconCheck.vue";

export default {
  name: "simple-user-list-table",

  components: {
    IconCheck,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    users: {
      type: Array,
      default: () => {
        return [];
      },
      description: "Users",
    },
  },

  data() {
    return {};
  },

  computed: {},

  watch: {},

  methods: {
    async viewUser(row) {
      this.$emit("onViewUser", row.id);
    },

    async deleteUser(row) {
      this.$emit("onDeleteUser", row.id);
    },
  },
};
</script>
