export const PRODUCT_ORIGIN_QUEBEC = "QUEBEC";
export const PRODUCT_ORIGIN_CANADA = "CANADA";
export const PRODUCT_ORIGIN_OTHER = "OTHER";
export const PRODUCT_ORIGIN_UNKNOWN = "UNKNOWN";
export const STATUS_INACTIVE = "INACTIVE";
export const STATUS_ACTIVE = "ACTIVE";
export const STATUS_BACKORDER = "BACKORDER";
export const STATUS_DELETED = "DELETED";

export const PRODUCT_STATUS = [
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  STATUS_DELETED,
  STATUS_BACKORDER,
];

export const UNIT_TYPE_REFERENCE_UNIT = "REFERENCE_UNIT";
export const UNIT_TYPE_BIGGER_THAN_REFERENCE = "BIGGER_THAN_REFERENCE";
export const UNIT_TYPE_SMALLER_THAN_REFERENCE = "SMALLER_THAN_REFERENCE";
export const UNIT_TYPES = [
  UNIT_TYPE_BIGGER_THAN_REFERENCE,
  UNIT_TYPE_SMALLER_THAN_REFERENCE,
  UNIT_TYPE_REFERENCE_UNIT,
];
