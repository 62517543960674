<template>
  <div>
    <div class="row mb-5">
      <div class="col-12" style="display: flex; align-items: center">
        <span class="display-4">
          {{ objectName }} - {{ $t("COMMON.ALLOWED_USERS") }}
        </span>
        <base-button
          @click="
            () => {
              userAddModalOpened = true;
            }
          "
          type="button"
          class="btn btn-sm btn-primary"
        >
          <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
          {{ $t("COMMON.ADD_USER") }}
        </base-button>
      </div>
    </div>

    <simple-user-list-table
      :users="object.allowedUsers"
      @onDeleteUser="
        (user) => {
          removeUser(user);
        }
      "
      @onViewUser="
        (user) => {
          viewUser(user);
        }
      "
    />

    <modal
      :show.sync="userAddModalOpened"
      modal-classes="modal-secondary"
      size="lg"
    >
      <form class="" @submit.prevent="">
        <div>
          <base-input>
            <user-selector
              :filterOrganization="object.organization.id"
              @userChanged="
                (user) => {
                  selectedUser = user;
                }
              "
            />
          </base-input>
        </div>
      </form>

      <template slot="footer">
        <button
          type="submit"
          class="btn btn-primary"
          @click="addUser"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          {{ $t("COMMON.ADD_USER") }}
        </button>

        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="
            () => {
              userAddModalOpened = false;
            }
          "
          :disabled="loading"
        >
          {{ $t("COMMON.CLOSE") }}
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import swal from "sweetalert2";
import { cloneDeep } from "lodash";
import SimpleUserListTable from "@/components/SimpleUserListTable.vue";
import UserSelector from "@/components/UserSelector.vue";

export default {
  name: "allowed-users-view",

  components: { SimpleUserListTable, UserSelector },

  props: ["object", "objectName", "objectStore"],

  data() {
    return {
      userAddModalOpened: false,
      selectedUser: null,
      loading: false,
    };
  },

  computed: {},

  created() {},

  methods: {
    async addUser() {
      this.loading = true;
      try {
        const objectData = cloneDeep(this.object);
        objectData.allowedUsers.push({
          id: this.selectedUser,
          type: "users",
        });
        await this.$store.dispatch(`${this.objectStore}/update`, objectData);
        this.loading = false;
        this.userAddModalOpened = false;
        this.$emit("objectUpdated", true);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async viewUser(userId) {
      let me = await this.$store.getters["profile/me"];
      if (userId === me.id) {
        this.$router.push({ name: "View Profile" });
      } else {
        this.$router.push({
          name: "View User",
          params: { id: userId },
        });
      }
    },

    async removeUser(userId) {
      this.loading = true;
      try {
        swal.fire({
          allowEscapeKey: false,
          allowOutsideClick: false,
          onOpen: () => {
            swal.showLoading();
          },
        });
        const objectData = cloneDeep(this.object);
        objectData.allowedUsers = objectData.allowedUsers.filter((user) => {
          return user.id !== userId;
        });
        await this.$store.dispatch(`${this.objectStore}/update`, objectData);
        this.loading = false;
        this.$emit("objectUpdated", true);
        swal.close();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },
  },

  mounted() {},

  watch: {
    object(object) {},
  },
};
</script>
