export const OPTION_TYPE_NUMBER = "NUMBER";
export const OPTION_TYPE_STRING = "STRING";
export const OPTION_TYPE_WYSIWYG = "WYSIWYG";
export const OPTION_TYPE_CHECKBOX = "CHECKBOX";
export const OPTION_TYPE_OBJECT = "OBJECT";
export const OPTION_TYPE_ARRAY = "ARRAY";

export const OPTION_FORCE_MODEL_USAGE = "FORCE_MODEL_USAGE";
export const OPTION_TYPE_MULTIPLE_OBJECTS = "MULTIPLE_OBJECTS";
export const OPTION_DEFAULT_SALES_ORDER_TERMS = "DEFAULT_SALES_ORDER_TERMS";
export const OPTION_DEFAULT_SALES_ORDER_DESCRIPTION =
  "DEFAULT_SALES_ORDER_DESCRIPTION";
export const OPTION_DEFAULT_SALES_INVOICE_TERMS = "DEFAULT_SALES_INVOICE_TERMS";
export const OPTION_DEFAULT_SALES_INVOICE_DESCRIPTION =
  "DEFAULT_SALES_INVOICE_DESCRIPTION";
export const OPTION_DEFAULT_PURCHASES_ORDER_TERMS =
  "DEFAULT_PURCHASES_ORDER_TERMS";
export const OPTION_DEFAULT_PURCHASES_ORDER_DESCRIPTION =
  "DEFAULT_PURCHASES_ORDER_DESCRIPTION";
export const OPTION_DEFAULT_PURCHASES_INVOICE_TERMS =
  "DEFAULT_PURCHASES_INVOICE_TERMS";
export const OPTION_DEFAULT_PURCHASES_INVOICE_DESCRIPTION =
  "DEFAULT_PURCHASES_INVOICE_DESCRIPTION";
export const OPTION_DEFAULT_PURCHASES_PAYMENT_TERMS =
  "DEFAULT_PURCHASES_PAYMENT_TERMS";
