<template>
  <div>
    <div>
      <div class="row">
        <div class="col-6 col-md-2">
          <base-input>
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              :placeholder="$t('COMMON.PER_PAGE')"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>

        <div class="col-6 col-md-2">
          <base-input>
            <category-group-selector
              :allowNone="false"
              :showAll="false"
              :isOnlyModels="false"
              :placeholder="$t('COMMON.CATEGORIES')"
              @categoryChanged="
                (categoryId) => {
                  selectedCategory = categoryId;
                }
              "
            />
          </base-input>
        </div>

        <div class="col-6 col-md-2">
          <base-input>
            <el-select
              :clearable="true"
              class="select-primary pagination-select"
              v-model="product_status"
              :placeholder="$t('COMMON.STATUS')"
            >
              <el-option
                class="select-primary"
                v-for="(item, key) in PRODUCT_STATUS"
                :key="key"
                :label="$t(`PRODUCTS.STATUS_${item}`)"
                :value="item"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>

        <div class="col-4 col-md-2">
          <base-input>
            <supplier-selector
              v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SUPPLIERS)"
              :filterOrganization="filterOrganization"
              :supplier="selectedSupplier"
              @supplierChanged="(supplierId) => (selectedSupplier = supplierId)"
            />
          </base-input>
        </div>

        <div class="col-6 col-md-2">
          <base-input
            v-model="query"
            type="search"
            prepend-icon="fas fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
        </div>
      </div>

      <el-table
        class="table-responsive align-items-center table-flush warehouse-product-list"
        header-row-class-name="thead-light"
        :data="warehouseProducts"
        type="expand"
        row-key="id"
        @sort-change="sortChange"
      >
        <div slot="empty" v-if="loading">
          <img src="/img/loading.gif" style="height: 100px; width: 100px" />
        </div>
        <el-table-column type="expand">
          <template slot-scope="props">
            <form class="" @submit.prevent="">
              <base-input
                :label="`${$t('COMMON.TAX_GROUPS')}`"
                :placeholder="$t('COMMON.TAX_GROUPS')"
                :disabled="!canEditProducts"
              >
                <tax-groups-selector
                  :taxGroups="warehouseProductsModel[props.row.id].taxGroups"
                  @taxGroupsChanged="
                    (taxGroups) => {
                      warehouseProductsModel[props.row.id].taxGroups =
                        taxGroups;
                      updateWarehouseProductDebounced(props.row.id);
                    }
                  "
                  :disabled="
                    !warehouseProductsModel[props.row.id].custom_taxation ||
                    !canEditProducts
                  "
                />
              </base-input>

              <base-input
                :label="$t('COMMON.EXCERPT')"
                :placeholder="$t('COMMON.EXCERPT')"
                input-classes="form-control-alternative"
              >
                <html-editor
                  :disabled="!canEditProducts"
                  v-model="warehouseProductsModel[props.row.id].excerpt"
                  @input="
                    (data) => {
                      warehouseProductsModel[props.row.id].excerpt = data;
                      updateWarehouseProductDebounced(props.row.id);
                    }
                  "
                >
                </html-editor>
              </base-input>
            </form>
          </template>
        </el-table-column>
        <el-table-column
          v-if="!filterWarehouse"
          :label="$t('COMMON.WAREHOUSE')"
          prop="warehouse.name"
          min-width="400px"
        >
          <template v-slot="{ row }">
            <base-input v-if="row.id < 0">
              <warehouse-selector
                :filterWarehousesNotInWarehouseId="warehouse?.id"
                :filterOrganization="filterOrganization"
                :warehouse="warehouseProductsModel[row.id].warehouse?.id"
                :allowNone="false"
                :showAll="false"
                :disabled="row.id > 0 || !canEditProducts"
                @warehouseChanged="
                  (warehouseId, warehouse) => {
                    onWarehouseChange(row, warehouseId, warehouse);
                  }
                "
              />
            </base-input>
            <object-link
              v-if="row.warehouse && row.id > 0"
              :object="row.warehouse"
            />
          </template>
        </el-table-column>
        <el-table-column
          v-if="!filterProduct"
          :label="$t('COMMON.PRODUCT')"
          prop="product.name"
          min-width="400px"
        >
          <template v-slot="{ row }">
            <base-input v-if="row.id < 0">
              <product-selector
                :filterProductsNotInWarehouseId="warehouse.id"
                :filterOrganization="filterOrganization"
                :product="warehouseProductsModel[row.id].product.id"
                :allowNone="false"
                :showAll="false"
                :disabled="row.id > 0 || !canEditProducts"
                @productChanged="
                  (productId, product) => {
                    onProductChange(row, productId, product);
                  }
                "
              />
            </base-input>
            <object-link
              v-if="row.product && row.id > 0"
              :object="row.product"
            />
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('COMMON.QUANTITY')"
          prop="sku"
          min-width="100px"
          v-if="!warehouse?.is_model"
          sortable="custom"
        >
          <template v-slot="{ row }">
            {{ row.quantity }}
            <object-link
              v-if="row.unitOfMeasureUnit"
              :object="row.unitOfMeasureUnit"
            />
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('PRODUCTS.SKU')"
          prop="sku"
          min-width="250px"
        >
          <template v-slot="{ row }">
            <base-input
              :placeholder="$t('PRODUCTS.SKU')"
              v-model="warehouseProductsModel[row.id].sku"
              input-classes="form-control-alternative"
              @change="updateWarehouseProductDebounced(row.id)"
              :disabled="forceModelUsage ? !warehouse?.is_model : false"
            >
            </base-input>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('PRODUCTS.CUSTOM')"
          prop="sku"
          min-width="150px"
        >
          <template v-slot="{ row }">
            <base-checkbox
              v-model="warehouseProductsModel[row.id].custom_buying_price"
              class=""
              @input="updateWarehouseProductDebounced(row.id)"
              :disabled="!canEditProducts"
            >
              <span class="form-control-label">
                {{ $t("PRODUCTS.CUSTOM_BUYING_PRICE") }}
              </span>
            </base-checkbox>
            <base-checkbox
              v-model="warehouseProductsModel[row.id].custom_selling_price"
              class=""
              @input="updateWarehouseProductDebounced(row.id)"
              :disabled="!canEditProducts"
            >
              <span class="form-control-label">
                {{ $t("PRODUCTS.CUSTOM_SELLING_PRICE") }}
              </span>
            </base-checkbox>
            <base-checkbox
              v-model="warehouseProductsModel[row.id].custom_taxation"
              class=""
              @input="updateWarehouseProductDebounced(row.id)"
              :disabled="!canEditProducts"
            >
              <span class="form-control-label">
                {{ $t("PRODUCTS.CUSTOM_TAXATION") }}
              </span>
            </base-checkbox>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('COMMON.SELLING_PRICE')"
          prop="selling_price"
          min-width="150px"
          sortable="custom"
        >
          <template v-slot="{ row }">
            <base-input
              class="my-5"
              :placeholder="$t('COMMON.SELLING_PRICE')"
              v-model="warehouseProductsModel[row.id].selling_price"
              input-classes="form-control-alternative"
              @change="updateWarehouseProductDebounced(row.id)"
              :disabled="
                !warehouseProductsModel[row.id].custom_selling_price ||
                !canEditProducts
              "
            />
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('COMMON.BUYING_PRICE')"
          prop="buying_price"
          min-width="150px"
          sortable="custom"
        >
          <template v-slot="{ row }">
            <base-input
              class="my-5"
              :placeholder="$t('COMMON.BUYING_PRICE')"
              v-model="warehouseProductsModel[row.id].buying_price"
              input-classes="form-control-alternative"
              @change="updateWarehouseProductDebounced(row.id)"
              :disabled="
                !warehouseProductsModel[row.id].custom_buying_price ||
                !canEditProducts
              "
            />
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          min-width="120px"
          v-if="!warehouse?.modelUsed"
        >
          <div slot-scope="{ row }" class="table-actions">
            <el-tooltip
              content="Loading"
              placement="top"
              v-if="warehouseProductsModel[row.id].loading"
            >
              <a
                type="text"
                class="table-action table-action-delete"
                data-toggle="tooltip"
              >
                <i class="fas fa-spinner fa-spin"></i>
              </a>
            </el-tooltip>

            <el-tooltip
              content="Delete"
              placement="top"
              v-if="$currentUserCan($permissions.PERM_EDIT_PRODUCTS)"
            >
              <a
                type="text"
                @click="deleteWarehouseProduct(row)"
                class="table-action table-action-delete"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fas fa-trash"></i>
              </a>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>

      <div class="row px-3 mt-3">
        <div v-if="loading" class="col-12 text-center">
          <i class="fas fa-spinner fa-spin fa-2x"></i>
        </div>
        <base-button
          type="info"
          class="btn-sm mr-1 col-12"
          style="width: 100%"
          @click="
            () => {
              addLine();
            }
          "
          v-if="canEditProducts"
        >
          <i class="fas fa-plus"></i>
          {{ $t("PRODUCTS.ADD_PRODUCT") }}
        </base-button>
      </div>
    </div>
    <div
      slot="footer"
      class="mt-5 col-12 d-flex justify-content-center justify-content-sm-between flex-wrap table-footer"
    >
      <div class="">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}

          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
      </div>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { OPTION_FORCE_MODEL_USAGE } from "@/constants/options";
import { PRODUCT_STATUS, STATUS_ACTIVE } from "@/constants/products";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ProductSelector from "@/components/ProductSelector.vue";
import WarehouseSelector from "@/components/WarehouseSelector.vue";
import TaxGroupsSelector from "@/components/TaxGroupsSelector.vue";
import SupplierSelector from "@/components/SupplierSelector.vue";
import CategoryGroupSelector from "@/components/CategoryGroupSelector.vue";
// import WarehouseAddBulkProductsForm from "@/components/WarehouseProduct/WarehouseAddBulkProductsForm";

export default {
  name: "product-list-table",

  components: {
    ProductSelector,
    // WarehouseAddBulkProductsForm,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    HtmlEditor,
    TaxGroupsSelector,
    SupplierSelector,
    CategoryGroupSelector,
    WarehouseSelector,
  },

  mixins: [requestErrorMixin],

  props: {
    product: {
      type: Object,
      default: null,
      description: "Product",
    },
    warehouse: {
      type: Object,
      default: null,
      description: "Warehouse",
    },
    filterProduct: {
      type: String,
      default: null,
      description: "Product id",
    },
    filterWarehouse: {
      type: String,
      default: null,
      description: "Warehouse id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    preSelectedSupplier: {
      type: String,
      default: null,
      description: "Supplier id",
    },
  },

  created() {
    if(this.warehouse) {
      this.$store.dispatch(
        "organizationOptions/getOrganizationConfig",
        this.warehouse.organization.id
      );
    }
  },

  data() {
    return {
      query: null,
      removed: false,
      product_status: STATUS_ACTIVE,
      PRODUCT_STATUS,
      selectedRows: [],
      sort: "product.name",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      warehouseProducts: [],
      products: [],
      productList: [],
      loading: true,
      selectedProduct: null,
      selectedSupplier: this.preSelectedSupplier,
      warehouseProductsModel: {},
      selectedCategory: null,
    };
  },

  mounted() {},

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },

    ...mapGetters({
      organizationConfig: "organizationOptions/organizationConfig",
    }),

    forceModelUsage() {
      return this.organizationConfig[OPTION_FORCE_MODEL_USAGE];
    },

    canEditProducts() {
      if (!this.forceModelUsage) {
        return true;
      }
      return !!this.warehouse?.is_model;
    },
  },

  watch: {
    pagination: {
      handler: "getListDebounced",
      immediate: true,
      deep: true,
    },
    filterProduct: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterWarehouse: {
      handler: "getListDebounced",
      immediate: true,
    },
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedProduct: {
      handler: "getListDebounced",
      immediate: true,
    },
    removed: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedSupplier: {
      handler: "getListDebounced",
      immediate: true,
    },
    warehouseProducts: {
      handler: "refreshWarehouseProducts",
      immediate: true,
    },
    product_status: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedCategory: {
      handler: "getListDebounced",
      immediate: true,
    },
    preSelectedSupplier: {
      handler: "updatePreSelectedSupplier",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    updateWarehouseProductDebounced: _.debounce(function (id) {
      this.updateWarehouseProduct(id);
    }, 300),

    updatePreSelectedSupplier() {
      this.selectedSupplier = this.preSelectedSupplier;
    },

    async getList() {
      this.loading = true;
      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: encodeURIComponent(this.query) } : {}),
            ...(this.product_status ? { status: this.product_status } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include:
            "product,warehouse,taxGroups,unitOfMeasureUnit,unitOfMeasureUnit.unitOfMeasure",
        };

        if (this.filterProduct) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              product: this.filterProduct,
            },
          };
        }
        if (this.selectedSupplier) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              supplier: this.selectedSupplier,
            },
          };
        }
        if (this.selectedProduct) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              product: this.selectedProduct,
            },
          };
        }
        if (this.filterWarehouse) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              warehouse: this.filterWarehouse,
            },
          };
        }
        if (this.selectedCategory) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              productInCategory: this.selectedCategory,
            },
          };
        }

        await this.$store.dispatch("warehouseProducts/list", params);
        this.warehouseProducts = await this.$store.getters[
          "warehouseProducts/list"
        ];

        this.total = this.$store.getters["warehouseProducts/listTotal"];
        this.loading = false;
        this.scrollToTop();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async deleteWarehouseProduct(row) {
      if (row.id > 0) {
        const confirmation = await swal.fire({
          title: this.$t("PRODUCTS.DELETE_THIS_WAREHOUSE_PRODUCT"),
          type: "question",
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: this.$t("COMMON.YES"),
          cancelButtonText: this.$t("COMMON.NO"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        if (confirmation.value !== true) {
          return;
        }
        await this.$store.dispatch("warehouseProducts/destroy", row.id);
      }

      this.warehouseProducts = this.warehouseProducts.filter(
        (item) => item.id != row.id
      );
    },

    addLine() {
      let newLinesIds = Object.values(this.warehouseProducts)
        .filter((item) => item.id < 0)
        .map((item) => item.id);
      newLinesIds.push(-1);
      const newLineId = Math.min(...newLinesIds) - 1;

      const newWarehouseProduct = {
        type: "warehouse-products",
        id: newLineId,
        sku: this.product ? this.product.sku : null,
        selling_price: this.product ? this.product.price : 0,
        buying_price: this.product ? this.product.price : 0,
        taxGroups: this.warehouse?.taxGroups ?? [],
        relationshipNames: ["product", "warehouse", "taxGroups"],
        product: {
          type: "products",
          id: this.product ? this.product.id : null,
        },
        warehouse: {
          type: "warehouses",
          id: this.warehouse ? this.warehouse.id : null,
        },
        custom_taxation: false,
        custom_selling_price: false,
        custom_buying_price: false,
        loading: false,
      };
      this.warehouseProducts.push(_.cloneDeep(newWarehouseProduct));
      this.warehouseProductsModel[newLineId] = _.cloneDeep(newWarehouseProduct);
    },

    onProductChange(row, productId, product) {
      this.warehouseProductsModel[row.id].product.id = productId;
      this.warehouseProductsModel[row.id].sku = product.sku;
      this.warehouseProductsModel[row.id].selling_price = product.selling_price;
      this.warehouseProductsModel[row.id].buying_price = product.buying_price;
      this.warehouseProductsModel[row.id].taxGroups = product.taxGroups;
      this.updateWarehouseProductDebounced(row.id);
    },

    onWarehouseChange(row, warehouseId, warehouse) {
      this.warehouseProductsModel[row.id].warehouse.id = warehouseId;
      this.updateWarehouseProductDebounced(row.id);
    },

    refreshWarehouseProducts() {
      const warehouseProductsModelData = {};
      for (const item of this.warehouseProducts) {
        warehouseProductsModelData[item.id] = _.cloneDeep(item);
        warehouseProductsModelData[item.id].loading = false;
      }
      this.warehouseProductsModel = warehouseProductsModelData;
    },

    async updateWarehouseProduct(id) {
      this.warehouseProductsModel[id].loading = true;
      const warehouseProductData = this.warehouseProductsModel[id];

      if (warehouseProductData.id > 0) {
        await this.$store.dispatch(
          "warehouseProducts/update",
          warehouseProductData
        );
      } else {
        if (
          warehouseProductData.product.id &&
          warehouseProductData.warehouse.id
        ) {
          delete warehouseProductData.id;
          await this.$store.dispatch(
            "warehouseProducts/add",
            warehouseProductData
          );
        }
      }
      this.getList();
    },

    scrollToTop() {
      // Scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: "smooth", // For smooth scrolling
      });
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>

<style>
.warehouse-product-list .el-table__row .form-group {
  margin-bottom: 0;
}
</style>
