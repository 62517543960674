var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-12",staticStyle:{"display":"flex","align-items":"center"}},[_c('span',{staticClass:"display-4"},[_vm._v(" "+_vm._s(_vm.objectName)+" - "+_vm._s(_vm.$t("COMMON.ALLOWED_USERS"))+" ")]),_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button"},on:{"click":() => {
            _vm.userAddModalOpened = true;
          }}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-plus"})]),_vm._v(" "+_vm._s(_vm.$t("COMMON.ADD_USER"))+" ")])],1)]),_c('simple-user-list-table',{attrs:{"users":_vm.object.allowedUsers},on:{"onDeleteUser":(user) => {
        _vm.removeUser(user);
      },"onViewUser":(user) => {
        _vm.viewUser(user);
      }}}),_c('modal',{attrs:{"show":_vm.userAddModalOpened,"modal-classes":"modal-secondary","size":"lg"},on:{"update:show":function($event){_vm.userAddModalOpened=$event}}},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',[_c('base-input',[_c('user-selector',{attrs:{"filterOrganization":_vm.object.organization.id},on:{"userChanged":(user) => {
                _vm.selectedUser = user;
              }}})],1)],1)]),_c('template',{slot:"footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.loading},on:{"click":_vm.addUser}},[(_vm.loading)?_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-spinner fa-spin"})]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("COMMON.ADD_USER"))+" ")]),_c('button',{staticClass:"btn btn-link ml-auto",attrs:{"type":"button","disabled":_vm.loading},on:{"click":() => {
            _vm.userAddModalOpened = false;
          }}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.CLOSE"))+" ")])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }