var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-control-label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_vm._l((_vm.phoneNumbersModel),function(line,key){return _c('div',{key:key,staticClass:"row"},[_c('div',{staticClass:"col-2"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.TYPE')}`}},[_c('el-select',{attrs:{"label":_vm.$t('COMMON.TYPE'),"placeholder":_vm.$t('COMMON.TYPE')},on:{"change":(type) => {
              line.type = type;
              _vm.phonesChanged();
            }},model:{value:(line.type),callback:function ($$v) {_vm.$set(line, "type", $$v)},expression:"line.type"}},_vm._l((_vm.phoneTypesOptions),function(value,key){return _c('el-option',{key:key,attrs:{"value":value,"label":_vm.$t(`COMMON.PHONE_TYPE_${value}`)}})}),1)],1)],1),_c('div',{staticClass:"col-6"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.PHONE')}`}},[_c('phone-number-input',{attrs:{"phoneNumber":line.phoneNumber},on:{"phoneNumberChanged":(phone) => {
              line.phoneNumber = phone;
              _vm.phonesChanged();
            }}})],1)],1),_c('div',{staticClass:"col-2"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.EXTENSION'),"placeholder":_vm.$t('COMMON.EXTENSION'),"inputClasses":'extension-input'},on:{"change":_vm.phonesChanged},model:{value:(line.extension),callback:function ($$v) {_vm.$set(line, "extension", $$v)},expression:"line.extension"}})],1),_c('div',{staticClass:"col-2 px-4"},[_c('label',{staticClass:"form-control-label mb-4"}),_c('base-button',{staticClass:"form-control btn btn-sm col-12 btn-danger",staticStyle:{"border":"1px solid #ccc"},attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return (() => _vm.removePhone(key)).apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-times"})])],1)])}),_c('base-button',{staticClass:"btn btn-sm col-12",staticStyle:{"border":"1px solid #ccc"},attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.addPhone.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-plus fa-2x"})])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }