<template>
  <div>
    <div class="row mb-5">
      <span class="display-4 col-12">
        {{ `${serviceCenter.name}` }} - {{ $t("COMMON.CONTACTS") }}
      </span>

      <div class="col-12 mt-4">
        <base-button
          type="primary"
          icon
          size="sm"
          @click="addServiceCenterContact()"
          v-if="$currentUserCan($permissions.PERM_CREATE_CONTACTS)"
        >
          <span class="btn-inner--icon">
            <i class="fas fa-plus"></i>
          </span>
          <span class="btn-inner--text">{{ $t("COMMON.ADD_CONTACT") }}</span>
        </base-button>
      </div>
    </div>

    <service-center-view-contacts-add-contact-modal
      :serviceCenter="serviceCenter"
      :showModal="showContactAddModal"
      @onCloseModal="onCloseModal"
    />

    <contact-list-table
      :filterOrganization="serviceCenter.organization.id"
      :filterContactableType="`App\\Models\\ServiceCenter`"
      :filterContactableId="serviceCenter.id"
      :key="rerenderKey"
    />
  </div>
</template>

<script>
import ServiceCenterViewContactsAddContactModal from "./ServiceCenterViewContactsAddContactModal.vue";
import ContactListTable from "../../../CrmModule/ContactManagement/partials/ContactListTable.vue";

export default {
  name: "service-center-view-contacts",

  components: { ServiceCenterViewContactsAddContactModal, ContactListTable },

  props: ["serviceCenter"],

  data() {
    return {
      showContactAddModal: false,
      rerenderKey: 0,
    };
  },

  computed: {},

  created() {},

  methods: {
    addServiceCenterContact() {
      this.showContactAddModal = true;
    },

    onCloseModal() {
      this.showContactAddModal = false;
      this.$emit("serviceCenterUpdated", true);
      this.rerenderKey++;
    },
  },

  mounted() {},

  watch: {
    serviceCenter(serviceCenter) {},
  },
};
</script>
