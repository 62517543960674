<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <h1>{{ `${serviceCenter.name}` }}</h1>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
          <dd class="col-sm-8">
            {{ serviceCenter.name }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.HAS_FOLDER") }}</dt>
          <dd class="col-sm-8">
            <icon-check :checked="!!serviceCenter.has_folder" />
          </dd>
        </dl>
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="serviceCenter.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(serviceCenter.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(serviceCenter.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import IconCheck from "@/components/IconCheck.vue";

export default {
  name: "service-center-view-global",
  components: { IconCheck },

  props: ["serviceCenter"],

  data() {
    return {};
  },

  created() {},

  methods: {
    serviceCenterUpdated() {
      this.$emit("serviceCenterUpdated", true);
    },
  },

  mounted() {},

  watch: {
    serviceCenter(serviceCenter) {},
  },
};
</script>
